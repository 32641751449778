import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Location } from '../models/location.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GetLocationPayload } from './location.types';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private dataUrl: string = 'locations/details/';
  protected location: Location = {} as Location;
  private uuid: string;
  locationChanged = new Subject<Location>();

  constructor(
    private readonly http: HttpClient,
    private router: Router,
  ) {}

  getLocation(): Location {
    return this.location;
  }

  getCurrency(): string {
    return this.location.currency;
  }

  fetchLocation(locationUuid: string): Observable<Location | undefined> {
    return this.http.get<GetLocationPayload>(this.dataUrl + locationUuid).pipe(
      map((payload) => {
        const location: Location = {
          ...payload.location,
          paymentGateways: payload.paymentGateways,
        };

        this.location = location;
        this.locationChanged.next(this.location);

        return location;
      }),
      catchError(() => {
        this.router.navigate(['/page-not-found']);
        return of(undefined);
      }),
    );
  }
}
